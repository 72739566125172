@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Oswald:wght@700&display=swap');
body {
  margin: 0;
  overflow-x: hidden;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'icomoon';
  src: url('./fonts/icomoon.eot?k5gmfw');
  src: url('./fonts/icomoon.eot?k5gmfw#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?k5gmfw') format('truetype'),
    url('./fonts/icomoon.woff?k5gmfw') format('woff'),
    url('./fonts/icomoon.svg?k5gmfw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-trophy:before {
  content: '\f091';
}
.icon-globe:before {
  content: '\f0ac';
}
.icon-bank:before {
  content: '\f19c';
}
.icon-institution:before {
  content: '\f19c';
}
.icon-university:before {
  content: '\f19c';
}
.icon-gavel:before {
  content: '\e900';
}
.icon-group:before {
  content: '\e904';
}
.icon-users:before {
  content: '\e904';
}
.icon-shield:before {
  content: '\e905';
}
.icon-flag:before {
  content: '\e906';
}
.icon-quote-left:before {
  content: '\e907';
}
.icon-gmail:before {
  content: '\e901';
  color: #d14836;
}
.icon-instagram1:before {
  content: '\e902';
  color: #e4405f;
}
.icon-facebook:before {
  content: '\e903';
  color: #1877f2;
}

*,
::after,
::before {
  box-sizing: border-box;
}

#root {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}
body {
  height: 100%;
}
section {
  height: 100%;
}

.hero {
  height: 100vh;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3em;
}

@media screen and (max-width: 1008px) {
  .hero {
    height: 70vh;
  }
  .hero-text {
    font-size: 2em;
  }
}
@media screen and (max-width: 512px) {
  .hero {
    height: 50vh;
  }
  .hero-text {
    font-size: 1.5em;
  }
}

.hero-image {
  /* Set a specific height */
  height: 100%;

  /* Position and center the image to scale nicely on all screens + Parallax effect */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

.sub-hero {
  height: 50vh;
  margin: 80px 0px;
}

.flex-container {
  width: 100%;
  position: relative;
  margin: 30px auto;
  padding: 0 50px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.flex-container h2 {
  font-size: 20px;
  color: #043e79;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 0px;
}

.flex-container h4 {
  color: #777;
}

.flex-container p {
  color: #777;
}

.flex-container a,
.flex-container a:visited {
  color: #043e79;
}

.flex-container img {
  width: 100%;
}

.justify-text {
  text-align: justify;
}

.col-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
}

.col-6 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
}

@media screen and (max-width: 1008px) {
  .flex-container {
    margin-bottom: 25px;
  }
  .flex-container .reverse {
    order: -1;
  }

  .col-4,
  .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .flex-container img {
    margin-bottom: 25px;
  }
}
